import React from 'react'
import styled from '@emotion/styled'

// -------------------------------------------------
// アクセントの／線
// -------------------------------------------------

const SlashBorder = styled.div`
  margin-top: 8em;
  position: relative;
  &:before {
    position: absolute;
    top: -5em;
    left: -2em;
    height: 1px;/*線の太さ*/
    width: 174px;/*長さ*/
    background: #121212;
    display: block;
    content: '';
    transform: rotate(-51.6deg);
  }
`

const Slash = props => {
  return <SlashBorder />
}

export default Slash
