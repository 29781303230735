import React from 'react'
import styled from '@emotion/styled'

const ItemInformation = styled.div`
  border-left: 1px solid ${props => props.theme.colors.primary};
  padding: ${props => props.padding ? props.padding : "1em"};
  li {
    margin: 0 0 1em;
    line-height: 1.7;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    font-weight: 600;
    transition: all 0.2s;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    &:visited {
      color: ${props => props.theme.colors.text};
   }
  }
`

const Information = props => {
  const { startDate, endDate, status } = props
  let { startD, endD } = ""
  let { startYear, startMonth, startDay, startHour, endYear, endMonth, endDay, endHour } = ""
  if (startDate) {
    startYear = startDate.substring(0, 4)
    startMonth = startDate.substring(5, 7)
    startDay = startDate.substring(8, 10)
    startHour = startDate.substring(11, 16)
  }
  if (endDate) {
    endYear = endDate.substring(0, 4)
    endMonth = endDate.substring(5, 7)
    endDay = endDate.substring(8, 10)
    endHour = endDate.substring(11, 16)
  }

  {
    (() => {
      if (endDate) {
        switch (status) {
          case "archive":
            startD = startYear + "/" + startMonth + "/" + startDay
            if (// 同じ年・月・日の場合はendDを表示しない
              (startYear === endYear) && (startMonth === endMonth) && (startDay === endDay)
            ) {
              break;
            } else if (// 年だけ、もしくは年・月が同じ場合はendDでは月・日を表示
              ((startYear === endYear) && (startMonth === endMonth)) ||
              ((startYear === endYear) && (startMonth !== endMonth))
            ) {
              endD = endMonth + "/" + endDay
            } else if (// 年・日ともに異なる場合はendDでは年・月・日を表示
              (startYear !== endYear) && (startMonth !== endMonth)
            ) {
              endD = endYear + "/" + endMonth + "/" + endDay
            }
            break;
          default: // current, upcomingの場合
            startD = startYear + "/" + startMonth + "/" + startDay + "/" + startHour
            if (// 同じ年・月・日・時の場合はendDを表示しない
              (startYear === endYear) && (startMonth === endMonth) && (startDay === endDay) && (startHour === endHour)
            ) {
              break;
            } else if (// 同じ年・月・日の場合はendDでは時のみ表示
              (startYear === endYear) && (startMonth === endMonth) && (startDay === endDay) && (startHour === endHour)
            ) {
              endD = end
            } else if (// 同じ年、もしくは同じ年・月の場合
              ((startYear === endYear) && (startMonth !== endMonth) && (startDay !== endDay)) || ((startYear === endYear) && (startMonth === endMonth) && (startDay !== endDay))
            ) {
              endD = endMonth + "/" + endDay + "/" + endHour
            } else if (// 異なる年・月・日の場合
              (startYear !== endYear) && (startMonth !== endMonth) && (startDay !== endDay)
            ) {
              endD = endYear + "/" + endMonth + "/" + endDay + "/" + endHour
            }
            break;
        }
      } else {
        switch (status) {
          case "archive":
            startD = startYear + "/" + startMonth + "/" + startDay
            break;
          default:
            startD = startYear + "/" + startMonth + "/" + startDay + "/" + startHour
            break;
        }
      }
    })()
  }

  return (
    <ItemInformation
      padding={props.padding}
      small={props.small}
    >
      {startDate && (
        <>
          {endD ?
            <>
              {startD + " –––> " + endD}
            </>
            :
            <>
              {startD}
            </>
          }
        </>
      )}
      {props.children}
    </ItemInformation>
  )
}

export default Information
