import React from "react";
import Slider from "react-slick";
import styled from '@emotion/styled'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledSlider = styled(Slider)`
  .slick-list {
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : "4em"};
  }
  .slick-arrow.slick-next, .slick-arrow.slick-prev {
    top: unset;
    bottom: -32px;
    width: 32px;
    &:before {
      color: ${props => props.theme.colors.primary};
      font-size: 14px;
      font-family: ${props => props.theme.fonts.body};
      font-weight: 600;
      opacity: unset;
    }
  }
  .slick-arrow.slick-next {
    right: 0;
    &:before {
      content: 'next';
    }
  }
  .slick-arrow.slick-prev {
    left: 0;
    &:before {
      content: 'prev';
    }
  }
  .slick-dots {
    width: 60%;
    -webkit-transform: translateX(-105%);
    transform: translateX(-50%);
    left: 50%;
  }
`

const SimpleSlider = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    croll: 1,
    zIndex: 1
  };
  return (
    <StyledSlider {...settings} marginBottom={props.marginBottom}>
      {props.children}
    </StyledSlider>
  );
}

export default SimpleSlider;
